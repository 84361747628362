import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Header from '../components/Header';
import Footer from '../components/Footer';

import smallcoin from '../images/Earn 2.png';
import bigcoin from '../images/Earn 1.svg';

import refbig from '../images/Ref 3.png';
import leadersbig from '../images/Leaders 2.png';
import tonlogo from '../images/ton_symbol (9).svg'

import MainPage from './MainPage';

import bigbronze from '../images/BronzeCup 2 (1).png';
import bigdiamond from '../images/DiamondCup 1 (1).png';
import biggold from '../images/GoldCup 1 (1).png';
import bigplatinum from '../images/PlatinumCup 1 (1).png';
import bigsilver from '../images/SilverCup 1 (1).png';

import '@telegram-apps/telegram-ui/dist/styles.css';
import { Placeholder } from '@telegram-apps/telegram-ui';
import { Modal, Button } from '@telegram-apps/telegram-ui';
import { ModalHeader } from '@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader';

function LeadersPage() {

    // Находим все элементы на странице, содержащие числа
    const allElements = document.querySelectorAll('*');

    allElements.forEach(element => {
        element.childNodes.forEach(child => {
            if (child.nodeType === 3) { // Проверяем, является ли текущий узел текстовым узлом
                const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/gm; // Регулярное выражение для поиска чисел
                child.nodeValue = child.nodeValue.replace(regex, '$1,'); // Заменяем формат чисел
            }
        });
    });

    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        window.location = '/home'
        BackButton.hide();
    });

    const [leagueImage, setLeagueImage] = useState(bigbronze);
    const [address, setAddress] = useState('EQBfBWT7X2BHg9tXAxzhz2aKiNTU1tpt5NsiK0uSDW_YAJ67');
    const [copied, setCopied] = useState(false);

    const shortenAddress = (fullAddress) => {
        const firstPart = fullAddress.slice(0, 4);
        const lastPart = fullAddress.slice(-4);
        return `${firstPart}...${lastPart}`;
    };

    useEffect(() => {

        setAddress(shortenAddress(address));

        const balanceElement = document.querySelector('.bb-item-right-balance');
        const balanceText = balanceElement.innerText.replace(/[^0-9]/g, '');
        const balance = parseInt(balanceText);

        if (balance < 10000) {
            setLeagueImage(bigbronze);
        } else if (balance >= 10000 && balance < 100000) {
            setLeagueImage(bigsilver);
        } else if (balance >= 100000 && balance < 2000000) {
            setLeagueImage(biggold);
        } else if (balance >= 2000000 && balance < 10000000) {
            setLeagueImage(bigplatinum);
        } else if (balance >= 10000000) {
            setLeagueImage(bigdiamond);
        }
    }, []);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    return (
        <div className='App'>
            <div className="Page">
                <div className="image-page">
                    <img src={leadersbig} alt="" />
                </div>
                <div className="image-title">
                    <span className="leaders-text">Leaders</span>
                </div>
                <div className="desc">Season time left:</div>
                <div className="timer">
                    <div className="timer-item">
                        <div className="days-value">00</div>
                        <div className="days-name">DAYS</div>
                    </div>
                    <div className="timer-item">
                        <div className="hours-value">00</div>
                        <div className="hours-name">HOURS</div>
                    </div>
                    <div className="timer-item">
                        <div className="mins-value">00</div>
                        <div className="mins-name">MINS</div>
                    </div>
                    <div className="timer-item">
                        <div className="sec-value">00</div>
                        <div className="sec-name">SEC</div>
                    </div>
                </div>
                <div className="descref">
                    <div className="block-desc-item center">
                        <div className="bb-item-right">
                            <div className="bb-item-right-balance">
                                PRIZE POOL: <span>$100000</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    dismissible
                    trigger={<div className='bigbutton'>
                        REWARDS
                    </div>}
                >
                    <Placeholder

                    >
                        <div className="defaultblock weight">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    1st - 3rd place
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    Reward: 200
                                    <img src={tonlogo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="defaultblock weight">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    4th - 10th place
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    Reward: 200
                                    <img src={tonlogo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="defaultblock weight">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    11th - 25th place
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    Reward: 200
                                    <img src={tonlogo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="defaultblock weight">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    26th - 50th place
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    Reward: 200
                                    <img src={tonlogo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="defaultblock weight">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    51 - 75th place
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    Reward: 200
                                    <img src={tonlogo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="defaultblock weight">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    76th - 100th place
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    Reward: 200
                                    <img src={tonlogo} alt="" />
                                </div>
                            </div>
                        </div>
                    </Placeholder>
                </Modal>
                <div className="block-buttons">
                    <div className="block-buttons-title">
                        Top 100
                    </div>
                    <div className="block-buttons-list">
                        <div className="block-buttons-item">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                    <div className="league">
                                        <img src={leagueImage} alt="" />
                                    </div>
                                </div>
                                <div className="bb-item-left-name">
                                    DevHvn
                                </div>
                            </div>
                            <div className="bb-item-left">
                                <CopyToClipboard text={address} onCopy={handleCopy}>
                                    <div className="bb-item-left-name" style={{ cursor: 'pointer' }}>
                                        {copied ? 'Copied!' : address}
                                    </div>
                                </CopyToClipboard>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    700000
                                    <img src={smallcoin} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeadersPage;    