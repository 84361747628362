import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import smallcoin from '../images/Earn 2.png';
import bigcoin from '../images/Earn 1.svg';

import refbig from '../images/Ref 3.png';
import leadersbig from '../images/Leaders 2.png';
import earnbig from '../images/Earn 4.png';
import shopbig from '../images/Shop 2.png';

import ref from '../images/Ref 2.png';
import leaders from '../images/Leaders 1.png';
import earn from '../images/Earn 3.png';
import shop from '../images/Shop 1.png';
import earnnew from '../images/EarnNew.png';
import shopnew from '../images/ShopNew.png';

import platinum from '../images/PlatinumCup 1.png';
import bronze from '../images/BronzeCup 2.png';
import gold from '../images/GoldCup 1.png';
import diamond from '../images/DiamondCup 1.png';
import MainPage from './MainPage';

import '@telegram-apps/telegram-ui/dist/styles.css';
import { Placeholder } from '@telegram-apps/telegram-ui';
import { Modal, Button } from '@telegram-apps/telegram-ui';
import { ModalHeader } from '@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader';

function ShopPage() {

    // Находим все элементы на странице, содержащие числа
    const allElements = document.querySelectorAll('*');

    allElements.forEach(element => {
        element.childNodes.forEach(child => {
            if (child.nodeType === 3) { // Проверяем, является ли текущий узел текстовым узлом
                const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/gm; // Регулярное выражение для поиска чисел
                child.nodeValue = child.nodeValue.replace(regex, '$1,'); // Заменяем формат чисел
            }
        });
    });

    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        window.location = '/home'
        BackButton.hide();
    });

    const [balance, setBalance] = useState(1000);
    const [currentEnergy, setCurrentEnergy] = useState(0);
    const [maxEnergy, setMaxEnergy] = useState(0);

    const { HapticFeedback } = window.Telegram.WebApp;

    useEffect(() => {
        fetch('https://inftclicker/api/user/123')
            .then(response => response.json())
            .then(data => {
                setBalance(data.balance);
                setCurrentEnergy(data.currentEnergy);
                setMaxEnergy(data.maxEnergy);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleAddBalance = () => {
        const newBalance = balance + 1;
        setBalance(newBalance);

        HapticFeedback.impactOccurred('heavy');

        fetch('https://inftclicker/api/user/123', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ balance: newBalance }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to update balance on the server');
                }
            })
            .catch(error => {
                console.error('Error updating balance:', error);
            });
    };

    return (
        <div className='App'>
            <div className="Page">
                <div className="image-page">
                    <img src={shopnew} alt="" />
                </div>
                <div className="image-title">
                    <span className="shop-text">Shop</span>
                </div>
                <div className="gap">
                    <div className="desc">Your Share Balance</div>
                    <div className="balance">
                        <div className="balance-img">
                            <img src={smallcoin} alt="" />
                        </div>
                        <div className="balance-text">
                            {balance.toLocaleString('en-US', { style: 'decimal' })}
                        </div>
                    </div>
                </div>
                <div className="block-buttons">
                    <div className="block-buttons-title">
                        Your Daily Boosters
                    </div>
                    <div className="block-buttons-list row">
                        <Modal
                            dismissible
                            trigger={<div className="block-buttons-item task">
                                <div className="bb-item-left">
                                    <div className="bb-item-left-avatar">
                                        <img src={shopnew} alt="" />
                                    </div>
                                    <div className="bb-item-left-name">
                                        Turbo
                                    </div>
                                </div>
                                <div className="bb-item-right">
                                    <div className="bb-item-right-level">
                                        3/3
                                    </div>
                                </div>
                            </div>}
                        >
                            <Placeholder
                                description="1 level"
                                header="Multitap"
                            >
                                <div className="bigbuttonmodal">Upgrade</div>
                            </Placeholder>
                        </Modal>
                        <Modal
                            dismissible
                            trigger={<div className="block-buttons-item task">
                                <div className="bb-item-left">
                                    <div className="bb-item-left-avatar">
                                        <img src={shopnew} alt="" />
                                    </div>
                                    <div className="bb-item-left-name">
                                        Full Energy
                                    </div>
                                </div>
                                <div className="bb-item-right">
                                    <div className="bb-item-right-level">
                                        3/3
                                    </div>
                                </div>
                            </div>}
                        >
                            <Placeholder
                                description="1 level"
                                header="Multitap"
                            >
                                <div className="bigbuttonmodal">Upgrade</div>
                            </Placeholder>
                        </Modal>
                    </div>
                </div>
                <div className="block-buttons">
                    <div className="block-buttons-title">
                        Boosters
                    </div>
                    <div className="block-buttons-list">
                        <Modal
                            dismissible
                            trigger={<div className="block-buttons-item task">
                                <div className="bb-item-left">
                                    <div className="bb-item-left-avatar">
                                        <img src={shopnew} alt="" />
                                    </div>
                                    <div className="bb-item-left-info">
                                        <div className="bb-item-left-name">
                                            Multitap
                                        </div>
                                        <div className="bb-item-right">
                                            <div className="bb-item-right-balance">
                                                <img src={smallcoin} alt="" />
                                                100 000
                                            </div>
                                            <div className="bb-item-right-level">
                                                1 level
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bb-item-right">
                                    Buy
                                </div>
                            </div>}
                        >
                            <Placeholder
                                description="1 level"
                                header="Multitap"
                            >
                                <div className="bigbuttonmodal">Upgrade</div>
                            </Placeholder>
                        </Modal>
                        <Modal
                            dismissible
                            trigger={<div className="block-buttons-item task">
                                <div className="bb-item-left">
                                    <div className="bb-item-left-avatar">
                                        <img src={shopnew} alt="" />
                                    </div>
                                    <div className="bb-item-left-info">
                                        <div className="bb-item-left-name">
                                            Energy Limit
                                        </div>
                                        <div className="bb-item-right">
                                            <div className="bb-item-right-balance">
                                                <img src={smallcoin} alt="" />
                                                100 000
                                            </div>
                                            <div className="bb-item-right-level">
                                                1 level
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bb-item-right">
                                    Buy
                                </div>
                            </div>}
                        >
                            <Placeholder
                                description="1 level"
                                header="Energy Limit"
                            >
                                <div className="bigbuttonmodal">Upgrade</div>
                            </Placeholder>
                        </Modal>
                        <Modal
                            dismissible
                            trigger={<div className="block-buttons-item task">
                                <div className="bb-item-left">
                                    <div className="bb-item-left-avatar">
                                        <img src={shopnew} alt="" />
                                    </div>
                                    <div className="bb-item-left-info">
                                        <div className="bb-item-left-name">
                                            Recharging Speed
                                        </div>
                                        <div className="bb-item-right">
                                            <div className="bb-item-right-balance">
                                                <img src={smallcoin} alt="" />
                                                100 000
                                            </div>
                                            <div className="bb-item-right-level">
                                                1 level
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bb-item-right">
                                    Buy
                                </div>
                            </div>}
                        >
                            <Placeholder
                                description="1 level"
                                header="Recharging Speed"
                            >
                                <div className="bigbuttonmodal">Upgrade</div>
                            </Placeholder>
                        </Modal>
                        <Modal
                            dismissible
                            trigger={<div className="block-buttons-item task">
                                <div className="bb-item-left">
                                    <div className="bb-item-left-avatar">
                                        <img src={shopnew} alt="" />
                                    </div>
                                    <div className="bb-item-left-info">
                                        <div className="bb-item-left-name">
                                            Tap Bot
                                        </div>
                                        <div className="bb-item-right">
                                            <div className="bb-item-right-balance">
                                                <img src={smallcoin} alt="" />
                                                100 000
                                            </div>
                                            <div className="bb-item-right-level">
                                                1 level
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bb-item-right">
                                    Buy
                                </div>
                            </div>}
                        >
                            <Placeholder
                                description="1 level"
                                header="Tap Bot"
                            >
                                <div className="bigbuttonmodal">Upgrade</div>
                            </Placeholder>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopPage;    