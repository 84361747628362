import './App.css';
import Header from './components/Header';
import MainPage from './components/MainPage';
import Footer from './components/Footer';
import Home from './components/Home';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Outlet } from 'react-router-dom';

import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot } from '@telegram-apps/telegram-ui';

function App() {
  return (
    <TonConnectUIProvider manifestUrl="https://api.rocketcat.site/tonconnect-manifest.json">
      <AppRoot>
        <div className="App">
          <Outlet />
        </div>
      </AppRoot>
    </TonConnectUIProvider>
  );
}

export default App;
