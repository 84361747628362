import React from 'react'
import energy from '../images/Energy 1.png';
import energynew from '../images/EnergyNew.png';

function Energy() {

// Находим все элементы на странице, содержащие числа
const allElements = document.querySelectorAll('*');

allElements.forEach(element => {
    element.childNodes.forEach(child => {
        if (child.nodeType === 3) { // Проверяем, является ли текущий узел текстовым узлом
            const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/gm; // Регулярное выражение для поиска чисел
            child.nodeValue = child.nodeValue.replace(regex, '$1,'); // Заменяем формат чисел
        }
    });
});

    return (
        <div className='Footer'>
            <div className="energy-container">
                <div className="energy-first">
                    <div className="energy-first-img">
                        <img src={energynew} alt="" />
                    </div>
                    <div className="energy-first-text">
                        750 / 1000
                    </div>
                </div>
                <div className="energy-two">
                    <progress max={1000} value={750}></progress>
                </div>
            </div>
        </div>
    )
}

export default Energy
