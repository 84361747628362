import React, { useState, useEffect } from 'react';
import logo from '../images/INFTLogo 1.png';
import logonew from '../images/INFTLogoNew.png';

import { TonConnect } from '@tonconnect/ui';
import { TonConnectButton } from '@tonconnect/ui-react';

function Header() {

    const [price, setPrice] = useState(0);

    useEffect(() => {
        fetch('https://api.geckoterminal.com/api/v2/networks/ton/tokens/EQAEfUNvB01k3khyyMJeQu6Y609TOPtm_-Mn0-12NJb4SXwR')
            .then(response => response.json())
            .then(data => {
                const newPrice = parseFloat(data.data.attributes.price_usd).toFixed(4);
                setPrice(newPrice);
            })
            .catch(error => {
                console.error('Error fetching price:', error);
            });
    }, []);

    return (
        <div className='Header'>
            <div className="header-container">
                <a href="https://dedust.io/swap/TON/INFT" target="_blank">
                    <div className="header-container-button">
                        <div className="header-container-button-img">
                            <img src={logonew} alt="" className="logo-img" />
                        </div>
                        <div className="header-container-button-text">
                            $INFT:
                            <span> ${price}</span>
                        </div>
                    </div>
                </a>
                <TonConnectButton />
            </div>
        </div>
    );
}

export default Header;