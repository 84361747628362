import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainPage from './MainPage';
import Energy from './Energy';

function Home() {
    return (
            <div className='App'>
                <Header />
                <MainPage />
                <Energy />
                <Footer />
            </div>
    )
}

export default Home
