import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import smallcoin from '../images/Earn 2.png';
import bigcoin from '../images/Earn 1.svg';

import refbig from '../images/Ref 3.png';

import premium from '../images/PremiumIcon.png';
import notpremium from '../images/TelegramIcon.png';

import platinum from '../images/PlatinumCup 1.png';
import bronze from '../images/BronzeCup 2.png';
import gold from '../images/GoldCup 1.png';
import diamond from '../images/DiamondCup 1.png';
import MainPage from './MainPage';

function RefPage() {

    // Находим все элементы на странице, содержащие числа
    const allElements = document.querySelectorAll('*');

    allElements.forEach(element => {
        element.childNodes.forEach(child => {
            if (child.nodeType === 3) { // Проверяем, является ли текущий узел текстовым узлом
                const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/gm; // Регулярное выражение для поиска чисел
                child.nodeValue = child.nodeValue.replace(regex, '$1,'); // Заменяем формат чисел
            }
        });
    });

    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        window.location = '/home'
        BackButton.hide();
    });

    return (
        <div className='App'>
            <div className="Page">
                <div className="image-page">
                    <img src={refbig} alt="" />
                </div>
                <div className="image-title">
                    <span className="referrals-text">Frens</span>
                </div>
                <div className="descref">
                    <div className="block-desc-item center">
                        <div className="bb-item-right">
                            <div className="bb-item-right-balance">
                                TOTAL PLAYERS: <span>4444</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="descref">
                    <div className="block-desc-item">
                        <div className="bb-item-left">
                            <div className="bb-item-left-avatar">
                                <img src={notpremium} alt="" />
                            </div>
                            <div className="bb-item-left-name">
                                Without Premium
                            </div>
                        </div>
                        <div className="bb-item-right">
                            <div className="bb-item-right-balance">
                                + 10 000
                                <img src={smallcoin} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="block-desc-item">
                        <div className="bb-item-left">
                            <div className="bb-item-left-avatar">
                                <img src={premium} alt="" />
                            </div>
                            <div className="bb-item-left-name">
                                With Premium
                            </div>
                        </div>
                        <div className="bb-item-right">
                            <div className="bb-item-right-balance">
                                + 25 000
                                <img src={smallcoin} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bigbutton">
                    Invite a Frens
                </div>
                <div className="block-buttons">
                    <div className="block-buttons-title">
                        My Frens (N):
                    </div>
                    <div className="block-buttons-list">
                        <div className="block-buttons-item">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    DevHvn
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    1 202 387
                                    <img src={smallcoin} alt="" />
                                </div>
                                <div className="bb-item-right-profit">
                                    (+ 10 000)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RefPage;    