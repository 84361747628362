import React from 'react'

import ref from '../images/Ref 2.png';
import leaders from '../images/Leaders 1.png';
import earn from '../images/Earn 3.png';
import shop from '../images/Shop 1.png';
import earnnew from '../images/EarnNew.png';
import shopnew from '../images/ShopNew.png';

function Footer() {
    return (
        <div className='Footer'>
            <div className="footer-container">
                <div className="footer-container-items">
                    <a href="/referrals">
                        <div className="footer-container-item">
                            <div className="footer-container-item-img">
                                <img src={ref} alt="" />
                            </div>
                            <div className="footer-container-item-text">
                                Frens
                            </div>
                        </div>
                    </a>
                    <a href="/leaders">
                        <div className="footer-container-item">
                            <div className="footer-container-item-img">
                                <img src={leaders} alt="" />
                            </div>
                            <div className="footer-container-item-text">
                                Leaders
                            </div>
                        </div>
                    </a>
                    <a href="/earn">
                        <div className="footer-container-item">
                            <div className="footer-container-item-img">
                                <img src={earnnew} alt="" />
                            </div>
                            <div className="footer-container-item-text">
                                Earn
                            </div>
                        </div>
                    </a>
                    <a href="/shop">
                        <div className="footer-container-item">
                            <div className="footer-container-item-img">
                                <img src={shopnew} alt="" />
                            </div>
                            <div className="footer-container-item-text">
                                Shop
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer
