import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import smallcoin from '../images/Earn 2.png';
import bigcoin from '../images/Earn 1.svg';

import refbig from '../images/Ref 3.png';
import leadersbig from '../images/Leaders 2.png';
import earnbig from '../images/Earn 4.png'

import platinum from '../images/PlatinumCup 1.png';
import bronze from '../images/BronzeCup 2.png';
import gold from '../images/GoldCup 1.png';
import diamond from '../images/DiamondCup 1.png';
import MainPage from './MainPage';

import '@telegram-apps/telegram-ui/dist/styles.css';

import { Spinner } from '@telegram-apps/telegram-ui';

function LoadPage() {

    setTimeout(function () {
        window.location.href = "/home";
    }, 2000);

    return (
        <div className='App gap'>
            <Spinner size="l" />
            <div className="desc">Loading...</div>
        </div>
    );
}

export default LoadPage;    