import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import smallcoin from '../images/Earn 2.png';
import bigcoin from '../images/Earn 1.svg';

import refbig from '../images/Ref 3.png';
import leadersbig from '../images/Leaders 2.png';
import earnbig from '../images/Earn 4.png'

import earnnew from '../images/EarnNew.png';
import shopnew from '../images/ShopNew.png';

import platinum from '../images/PlatinumCup 1.png';
import bronze from '../images/BronzeCup 2.png';
import gold from '../images/GoldCup 1.png';
import diamond from '../images/DiamondCup 1.png';
import MainPage from './MainPage';

import '@telegram-apps/telegram-ui/dist/styles.css';
import { Placeholder } from '@telegram-apps/telegram-ui';
import { Modal, Button } from '@telegram-apps/telegram-ui';
import { ModalHeader } from '@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader';

function EarnPage() {

    // Находим все элементы на странице, содержащие числа
    const allElements = document.querySelectorAll('*');

    allElements.forEach(element => {
        element.childNodes.forEach(child => {
            if (child.nodeType === 3) { // Проверяем, является ли текущий узел текстовым узлом
                const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/gm; // Регулярное выражение для поиска чисел
                child.nodeValue = child.nodeValue.replace(regex, '$1,'); // Заменяем формат чисел
            }
        });
    });

    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        window.location = '/home'
        BackButton.hide();
    });
    return (
        <div className='App'>
            <div className="Page">
                <div className="image-page">
                    <img src={earnnew} alt="" />
                </div>
                <div className="image-title">
                    <span className="earn-text">Earn</span>
                </div>
                <form action="">
                    <input className="block-buttons-item task" placeholder='ENTER THE PROMO CODE' />
                    <div className="bigbutton">ACTIVATE</div>
                </form>
                <div className="block-buttons">
                    <div className="block-buttons-title">
                        Subscription Rewards
                    </div>
                    <div className="block-buttons-list">
                        <Modal
                            dismissible
                            trigger={<div className="block-buttons-item task">
                                <div className="bb-item-left">
                                    <div className="bb-item-left-avatar">
                                    </div>
                                    <div className="bb-item-left-name">
                                        Telegram Channel
                                    </div>
                                </div>
                                <div className="bb-item-right">
                                    <div className="bb-item-right-balance">
                                        + 7500
                                        <img src={smallcoin} alt="" />
                                    </div>
                                </div>
                            </div>}
                        >
                            <Placeholder
                                description="+ 7500"
                                header="Telegram Channel"
                            >
                                <div className="bigbuttonmodal">Subcribe</div>
                            </Placeholder>
                        </Modal>
                        <div className="block-buttons-item task">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    Telegram Channel
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    + 10000
                                    <img src={smallcoin} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-buttons">
                    <div className="block-buttons-title">
                        Referral Rewards
                    </div>
                    <div className="block-buttons-list">
                        <div className="block-buttons-item task">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    5 Referrals
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    + 50000
                                    <img src={smallcoin} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="block-buttons-item task">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    15 Referrals
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    + 150000
                                    <img src={smallcoin} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-buttons">
                    <div className="block-buttons-title">
                        Other Rewards
                    </div>
                    <div className="block-buttons-list">
                        <div className="block-buttons-item task">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    Task
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    + 50000
                                    <img src={smallcoin} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="block-buttons-item task">
                            <div className="bb-item-left">
                                <div className="bb-item-left-avatar">
                                </div>
                                <div className="bb-item-left-name">
                                    Task
                                </div>
                            </div>
                            <div className="bb-item-right">
                                <div className="bb-item-right-balance">
                                    + 150000
                                    <img src={smallcoin} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EarnPage;    