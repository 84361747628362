import React, { useState, useEffect } from 'react';
import Footer from './Footer';

import energy from '../images/Energy 1.png';

import smallcoin from '../images/Earn 2.png';
import bigcoin from '../images/Earn 1.svg';
import bigcoinnew from '../images/CoinImgNew.png'

import platinum from '../images/PlatinumCup 1.png';
import bronze from '../images/BronzeCup 2.png';
import gold from '../images/GoldCup 1.png';
import diamond from '../images/DiamondCup 1.png';

function MainPage() {

    function createRuble(event) {
        const ruble = document.createElement('div');
        ruble.textContent = '+1';
        ruble.classList.add('ruble-animation');

        ruble.style.left = event.clientX + 'px';
        ruble.style.top = event.clientY + 'px';

        document.querySelector('.three-level').appendChild(ruble);

        setTimeout(() => {
            ruble.classList.add('ruble-animation-out');
            setTimeout(() => {
                ruble.remove();
            }, 10000);
        }, 200);
    }

    // Находим все элементы на странице, содержащие числа
    const allElements = document.querySelectorAll('*');

    allElements.forEach(element => {
        element.childNodes.forEach(child => {
            if (child.nodeType === 3) { // Проверяем, является ли текущий узел текстовым узлом
                const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/gm; // Регулярное выражение для поиска чисел
                child.nodeValue = child.nodeValue.replace(regex, '$1,'); // Заменяем формат чисел
            }
        });
    });

    const [balance, setBalance] = useState(1000);
    const [currentEnergy, setCurrentEnergy] = useState(0);
    const [maxEnergy, setMaxEnergy] = useState(0);

    const { HapticFeedback } = window.Telegram.WebApp;

    useEffect(() => {
        fetch('https://inftclicker/api/user/123')
            .then(response => response.json())
            .then(data => {
                setBalance(data.balance);
                setCurrentEnergy(data.currentEnergy);
                setMaxEnergy(data.maxEnergy);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleAddBalance = () => {

        const balanceElement = document.querySelector('.balance-text');
        balanceElement.classList.add('animate');

        // Remove the class after the animation ends
        setTimeout(() => {
            balanceElement.classList.remove('animate');
        }, 500);

        const newBalance = balance + 1;
        setBalance(newBalance);

        HapticFeedback.impactOccurred('heavy');

        fetch('https://inftclicker/api/user/123', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ balance: newBalance }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to update balance on the server');
                }
            })
            .catch(error => {
                console.error('Error updating balance:', error);
            });
    };

    return (
        <div className='MainPage'>
            <div className="not">
                <div className="first-level">
                    <div className="balance">
                        <div className="balance-img">
                            <img src={bigcoinnew} alt="" />
                        </div>
                        <div className="balance-text">
                            {balance.toLocaleString('en-US', { style: 'decimal' })}
                        </div>
                    </div>
                </div>
                <div className="two-level">
                    <div className="league">
                        <a href="/leagues">
                            <div className="league-text">
                                <img src={bronze} alt="" />
                                League:
                                <span className='league-name'> Bronze</span>
                                &gt;
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="three-level" onTouchStart={handleAddBalance} onClick={(event) => createRuble(event)}>
                <img src={bigcoinnew} alt="" className="bigcoin-img" />
            </div>
        </div>
    );
}

export default MainPage;