import React, { useState, useEffect } from 'react';

import smallcoin from '../images/Earn 2.png';
import bigcoin from '../images/Earn 1.svg';

import bigbronze from '../images/BronzeCup 2 (1).png';
import bigdiamond from '../images/DiamondCup 1 (1).png';
import biggold from '../images/GoldCup 1 (1).png';
import bigplatinum from '../images/PlatinumCup 1 (1).png';
import bigsilver from '../images/SilverCup 1 (1).png';

function LeaguesPage() {

    // Находим все элементы на странице, содержащие числа
    const allElements = document.querySelectorAll('*');

    allElements.forEach(element => {
        element.childNodes.forEach(child => {
            if (child.nodeType === 3) { // Проверяем, является ли текущий узел текстовым узлом
                const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/gm; // Регулярное выражение для поиска чисел
                child.nodeValue = child.nodeValue.replace(regex, '$1,'); // Заменяем формат чисел
            }
        });
    });

    const [balance, setBalance] = useState(7000);
    const [league, setLeague] = useState('Bronze League');
    const [nextLeague, setNextLeague] = useState('Silver League');
    const [remainingAmount, setRemainingAmount] = useState('');
    const [nextLeagueAmount, setNextLeagueAmount] = useState(10000);

    const BackButton = window.Telegram.WebApp.BackButton;

    BackButton.show();
    BackButton.onClick(() => {
        window.location = '/home'
        BackButton.hide();
    });

    useEffect(() => {
        if (balance >= 10000000) {
            setLeague('Diamond League');
            setNextLeague('10000');
            setRemainingAmount('100000000000');
            setNextLeagueAmount('10000000000000');
        } else if (balance >= 2000000) {
            setLeague('Platinum League');
            setNextLeague('Diamond League');
            setNextLeagueAmount(10000000);
        } else if (balance >= 100000) {
            setLeague('Gold League');
            setNextLeague('Platinum League');
            setNextLeagueAmount(2000000);
        } else if (balance >= 10000) {
            setLeague('Silver League');
            setNextLeague('Gold League');
            setNextLeagueAmount(100000);
        } else {
            setLeague('Bronze League');
            setNextLeague('Silver League');
            setNextLeagueAmount(10000);
        }

        if (nextLeagueAmount === '∞') {
            setRemainingAmount('∞');
        } else {
            setRemainingAmount((nextLeagueAmount - balance).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
        }
    }, [balance]);

    const leagueImage = {
        'Bronze League': bigbronze,
        'Silver League': bigsilver,
        'Gold League': biggold,
        'Platinum League': bigplatinum,
        'Diamond League': bigdiamond
    };

    const leagueProgress = ((balance / 10000000) * 100).toFixed(2);

    const handlePreviousLeague = () => {
        switch (league) {
            case 'Silver League':
                setLeague('Bronze League');
                setNextLeague('Silver League');
                setNextLeagueAmount(10000);
                break;
            case 'Gold League':
                setLeague('Silver League');
                setNextLeague('Gold League');
                setNextLeagueAmount(100000);
                break;
            case 'Platinum League':
                setLeague('Gold League');
                setNextLeague('Platinum League');
                setNextLeagueAmount(2000000);
                break;
            case 'Diamond League':
                setLeague('Platinum League');
                setNextLeague('Diamond League');
                setNextLeagueAmount(10000000);
                break;
            default:
                break;
        }
    };

    const handleNextLeague = () => {
        switch (league) {
            case 'Bronze League':
                setLeague('Silver League');
                setNextLeague('Gold League');
                setNextLeagueAmount(100000);
                break;
            case 'Silver League':
                setLeague('Gold League');
                setNextLeague('Platinum League');
                setNextLeagueAmount(2000000);
                break;
            case 'Gold League':
                setLeague('Platinum League');
                setNextLeague('Diamond League');
                setNextLeagueAmount(10000000);
                break;
            case 'Platinum League':
                setLeague('Diamond League');
                setNextLeague('∞');
                setNextLeagueAmount('∞');
                break;
            default:
                break;
        }
    };

    return (
        <div className='App'>
            <div className="Page">
                <div className="image-title">
                    <span className="leaders-text default">{league}</span>
                    <span className='desc'>The amount of your balance determines the league you belong to</span>
                </div>
                <div className="image-page">
                    <div className="left" onClick={handlePreviousLeague}>&lt;</div>
                    <img src={leagueImage[league]} alt="" />
                    <div className="right" onClick={handleNextLeague}>&gt;</div>
                </div>
                <div className="block-buttons">
                    <div className="progress-league">
                        <div className="progress-league-text">
                            {balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} / {nextLeague === '∞' ? '-' : nextLeagueAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                        </div>
                        <div className="progress-league-prog">
                            <progress max={nextLeagueAmount === '∞' ? 1 : nextLeagueAmount} value={balance}></progress>
                        </div>
                    </div>
                </div>
                <div className="defaultblock">
                    <div className="bb-item-left">
                        <div className="bb-item-left-avatar">
                        </div>
                        <div className="bb-item-left-name">
                            Your Reward
                        </div>
                    </div>
                    <div className="bb-item-right">
                        <div className="bb-item-right-balance">
                            {balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                            <img src={smallcoin} alt="" />
                        </div>
                    </div>
                </div>
                <div className="bigbutton">
                    CLAIM REWARD
                </div>
            </div>
        </div>
    );
}

export default LeaguesPage;