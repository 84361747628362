import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './components/Home';
import RefPage from './components/RefPage';
import LeadersPage from './components/LeadersPage';
import EarnPage from './components/EarnPage';
import ShopPage from './components/ShopPage';
import LoadPage from './components/LoadPage';
import LeaguesPage from './components/LeaguesPage';

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '/',
        element: <LoadPage />
      },
      {
        path: '/home',
        element: <Home />
      },
      {
        path: '/referrals',
        element: <RefPage />
      },
      {
        path: '/leaders',
        element: <LeadersPage />
      },
      {
        path: '/earn',
        element: <EarnPage />
      },
      {
        path: '/shop',
        element: <ShopPage />
      },
      {
        path: '/loading',
        element: <LoadPage />
      },
      {
        path: '/leagues',
        element: <LeaguesPage />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);